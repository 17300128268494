#root {
  width: 100%;
  height: Calc(100% - 120px);
  font-size: 0px;
  min-height: 355px;
}

.leftPanel {
  width: 26.9%;
  max-width: 200px;
  min-width: 175px;
  height: calc(100% - 10px);
  margin-top: 10px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 2px;
  font-size: 16px;
  background-color: #1f2547;
  margin-right: 10px;
}

.chartPanel {
  height: calc(100% - 10px);
  width: calc(100% - 210px - 210px - 10px);
  min-width: 300px;
  display: inline-block;
  font-size: 16px;
  background-color: #1f2547;
  margin-top: 10px;
  margin-right: 10px;
  flex-grow: 1;
}

.depthPanel {
  width: 26.5%;
  max-width: 200px;
  min-width: 175px;
  margin-top: 10px;
  height: calc(100% - 10px);

  font-size: 16px;
  margin-right: 10px;
}

.sellerOB,
.buyerOB {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  row-gap: 3px;
  min-width: 150px;
  height: 50%;
  background-color: #1f2547;
}

#footerPanel {
  height: 50px;
  width: 100%;
  font-size: 16px;
  background-color: #1f2547;
  margin-top: 10px;
}

.eBarHolder > h5 {
  color: white;
}

.coinPrice {
  display: inline-block;
  width: 45%;
  text-align: center;
}

.coinAbbr {
  display: inline-block;
  width: 40%;
  text-align: center;
}

.removeBarBtn {
  display: inline-block;
  width: calc(15% - 4px);
  cursor: pointer;
  margin-right: 3px;
}

.removeBarBtn:hover {
  background-color: red;
}

.addBarBtn {
  width: 100%;
}

.eBarHolder {
  display: inline-block;
  width: calc(100% - 5px);
  border: 1px solid white;
  max-height: 48px;
  align-content: center;
  cursor: pointer;
  flex: 1;
  min-width: 150px;
  max-width: 205px;
  border-radius: 8px;
}

.eBarHolder:hover {
  border-color: orange;
  transform: scaleX(1.01);
}

#leftAndMainPanels {
  width: calc(100% - 10px);
  height: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  flex-wrap: wrap;
}

.leftPanelUtil {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: cadetblue;
}

.searchBox {
  display: inline-block;
  height: 24px;
  width: 30%;
  min-width: fit-content;
  border-radius: 5px;
  border: none;
  margin-top: 3px;
  text-align: center;
}

.searchBox:hover {
  border: 1px solid orange;
}

.resultPanelWrapper {
  position: relative;
  width: 100%;
  height: 0px;
}

.resultPanel {
  position: absolute;
  width: 100%;
  background-color: antiquewhite;
  height: fit-content;
  max-height: 350px;
  overflow-y: scroll;
}

.chartHeader {
  width: 100%;
  height: 30px;
  background-color: cadetblue;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.chartHeader > h4 {
  display: inline-block;
  text-align: center;
  color: white;
}

.resetChartPosition {
  background-color: rgba(255, 0, 0, 0.3);
}

.resetChartPosition:hover {
  transform: scaleX(1.05);
  background-color: rgba(255, 0, 0, 0.7);
  cursor: pointer;
}

.chartHolder {
  width: 100%;
  height: calc(100% - 30px);
}

.depthBarHolder {
  max-height: 30px;
  background-color: #1f2547;
  align-content: center;
  cursor: default;
  flex: 1;
}

.depthBarHolder > h4 {
  display: inline-block;
  width: 50%;
  color: white;
  text-align: center;
  cursor: default;
}

.depthHeader > h4 {
  width: 50%;
  color: white;
  display: inline-block;
  text-align: center;
  height: 30px;
  align-content: center;
}

.depthHeader {
  width: 100%;
  background-color: cadetblue;
}

.sellerDepthBar {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid red;
}

.buyerDepthBar {
  background-color: rgba(0, 255, 0, 0.1);
  border: 1px solid rgb(110, 174, 13);
}

@keyframes color-blink {
  to {
    color: white;
  }
}

@keyframes color-blink-seller {
  to {
    color: red;
  }
}

@keyframes color-blink-buyer {
  to {
    color: rgb(110, 174, 13);
  }
}

.sellerDepthBar > h4 {
  animation: color-blink-seller 0.2s linear;
}

.buyerDepthBar > h4 {
  animation: color-blink-buyer 0.2s linear;
}

@media screen and (max-width: 820px) and (min-width: 650px) {
  .sellerOB,
  .buyerOB {
    font-size: 14px;
  }
}

@media screen and (min-width: 721px) and (max-height: 590px) {
  #header {
    min-height: 30px;
    text-align: center;
    height: 10%;
  }

  #header > * {
    display: inline-block;
  }

  #root {
    height: calc(90% - 10px);
  }

  #footer {
    flex: 1;
  }
}

@media screen and (max-width: 720px) {
  .leftPanel {
    min-width: calc(100% - 10px);
    min-height: 10px;
    height: min-content;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    margin-top: 5px;
  }

  .depthPanel {
    min-width: calc(100% - 10px);
    height: 210px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .sellerOB,
  .buyerOB {
    width: calc(50% - 5px);
    height: 100%;
  }

  .buyerOB {
    margin-left: 2px;
  }

  .chartPanel {
    min-width: calc(100% - 10px);
    height: 300px;
  }

  #leftAndMainPanels {
    height: fit-content;
    flex-direction: column-reverse;
  }

  #footerPanel {
    min-width: calc(100% - 10px);
    height: 50px;
  }

  .leftPanelUtil {
    display: inline-block;
    width: auto;
  }

  .eBarHolder {
    width: 150px;
    margin-inline: 2px;
    margin-top: 2px;
  }

  #header {
    height: 30px;
    text-align: center;
  }

  #header > h2,
  p {
    display: inline-block;
    font-size: 12px;
  }

  #root {
    height: fit-content;
  }
}

@media screen and (max-width: 330px) {
  .depthPanel {
    height: max-content;
  }

  .sellerOB,
  .buyerOB {
    width: calc(100%);
    min-width: 175px;
  }

  #header > p {
    display: none;
  }

  .eBarHolder {
    width: 100%;
  }

  .chartHeader {
    height: min-content;
  }
}
